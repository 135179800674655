import { Routes, Route } from 'react-router-dom';
import Home from './container/Home';
import Events from './container/Events';
import Partnership from './container/Partnership';
import Error404 from './components/Error';
import Blog from './container/Blog'
import BlogDetail from './components/BlogDetail'
import Login from './components/Login'
import Register from './components/Register';
import { Provider } from 'react-redux';
import store from './store/store';
import Portal from './container/Portal';
import ProtectedRoute from './components/ProtectedRoute';

export default function App() {
    return (
        <Provider store={store}>

            <Routes >
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog-name" element={<BlogDetail />} />
                <Route path="/events" element={<Events />} />
                <Route path="/portal" element={
                    <ProtectedRoute>
                        <Portal />
                    </ProtectedRoute>
                } />

                <Route path="/partnership" element={<Partnership />} />
                {/* <Route path="/galery" element={<Galery />} /> */}
                {/* <Route path="/about" element={<About />} /> */}
                <Route path="/home" element={<Home />} />
                <Route path="/" element={<Home />} />
                <Route path="/*" element={<Error404 />} />
            </Routes>
        </Provider>

    );

}