import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

export default function ProtectedRoute({ children }) {
    const location = useLocation();
    const isAuthenticated = false;

    if (!isAuthenticated) {
        // Kullanıcı giriş yapmamışsa, Login sayfasına yönlendir
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children; // Kullanıcı giriş yapmışsa, korunan rota içeriğini göster
};

