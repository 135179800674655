import { apiClient } from './apiClient';

export const userService = {
  async fetchUsers() {
    return apiClient('get-users');
  },

  async createUser(userData) {
    return apiClient('register', { body: userData, method: 'POST' });
  },

  // Diğer kullanıcı işlemleri...
};
