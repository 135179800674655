import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { setToken } from "../store/actions/authAction"

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const token = useSelector(state => state.auth.token);
  const dispatch = useDispatch();

  const updateToken = (newToken) => {
    dispatch(setToken(newToken));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://casval-backend-3847.vercel.app/api/v1/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      updateToken(data.token);
      console.log(token);

    } catch (error) {
      console.error('Kayıt sırasında bir hata oluştu: ', error);
    }
  };
  return (
    <div>
      <section class="bg-gray-50 dark:bg-gray-900">
        <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">

          <Link to="/home" className="flex items-center mb-6 text-2xl  text-gray-900 dark:text-white">
            <img src={require("../assets/logo.png")} className="w-8 h-8 mr-2" alt="CASVAL Logo" />
            <span className="self-center text-2xl whitespace-nowrap dark:text-white">CASVAL; Create & Share Value</span>
          </Link>
          <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Hesabına Giriş Yap
              </h1>
              <form class="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Eposta</label>
                  <input type="email" name="email" value={email}
                    onChange={(e) => setEmail(e.target.value)} class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-400 dark:focus:border-green-400" placeholder="name@casval.org" required="" />
                </div>
                <div>
                  <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Şifre</label>
                  <input type="password" name="password" value={password}
                    onChange={(e) => setPassword(e.target.value)} placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-400 dark:focus:border-green-400" required="" />
                </div>
                <div class="flex items-center justify-between">
                  <div class="flex items-start">
                    <div class="flex items-center h-5">
                      <input id="remember" aria-describedby="remember" type="checkbox" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-green-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-green-500 dark:ring-offset-gray-800" required="" />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="remember" class="text-gray-500 dark:text-gray-300">Beni Hatırla</label>
                    </div>
                  </div>
                  <a href="#" class="text-sm font-medium text-green-500 hover:underline dark:text-green-400">Şifreni mi unuttun?</a>
                </div>
                <button type="submit" class="w-full text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-500 dark:hover:bg-green-600 dark:focus:ring-green-700">Giriş Yap</button>
                <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                  Henüz hesabın yok mu? <Link to="/register" class="font-medium text-green-500 hover:underline dark:text-green-400">Kayıt Ol</Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
