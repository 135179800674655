// src/api/apiClient.js

const API_BASE_URL = 'http://localhost:5001'; // API'nizin temel URL'si

export async function apiClient(endpoint, { body, ...customConfig } = {}) {
  const headers = { 'Content-Type': 'application/json' };

  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  const response = await fetch(`${API_BASE_URL}/api/v1/${endpoint}`, config);

  if (!response.ok) {
    throw new Error(await response.text());
  }

  return response.json();
}
